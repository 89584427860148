<script>


import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import TaxPaymentDialog from './components/tax_payment_dialog.vue'

import { getArApApi } from '@/api/cash'




/**
 * User list component
 */
export default {
  page: {
    title: "Account Payable ",
    meta: [
      {
        name: "Tax Payment",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      list: [],
      title: "Tax Payment",
      items: [
        {
          text: "Account Payable",
          href: "/",
        },
        {
          text: "Tax Payment",
          href: "/",
        },
      ],

      start_date : '',
      end_date   : '',
      tax_data   : {
        open_balance : {
                gst_collected : 0,
                gst_itc       : 0,
                net           : 0,
            },

            active:  {
                gst_collected : 0,
                gst_itc       : 0,
                net           : 0,
            },

            details : [],

            closed : {
                gst_collected : 0,
                gst_itc       : 0,
                net           : 0,
            }
      }
     
     
    };
  },
  components: {
    Layout,
    PageHeader,
    flatPickr,
    TaxPaymentDialog
    
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.totalRows
    },

  },

  created() {
    
  },
  mounted() {

  },
  methods: {

    onQuery() {
      console.log('=====', this.start_date)
      getArApApi().query_tax_summary({start_date : this.start_date, end_date : this.end_date}).then(res => {
        if (res.errCode == 0) {
          this.tax_data = res.data
        }
      })
    },

    onPayment() {

    }

  },

};
</script>

<style>
.local_border {
  border-bottom-width : 2px
}

</style>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
 


            <div class="row">
            
              <div class="col-md-2">           
                <flat-pickr v-model="start_date" placeholder="Query by Start Date" class="form-control" @on-change="onQuery"></flat-pickr>
              </div>
              <div class="col-md-2">           
                <flat-pickr v-model="end_date" placeholder="Query by End Date" class="form-control"  @on-change="onQuery"></flat-pickr>
              </div>
              

              <div class="col-md-3"></div>
              <div class="col-md-5 d-flex flex-wrap align-items-start justify-content-md-end mt-2 mt-md-0 gap-2 mb-3">
                <b-button variant="primary" v-b-modal.modal-tax-payment-dialog >Make Payment</b-button>
              </div>
              <!-- end col -->
            </div>
            
            <b-modal centerd id="modal-tax-payment-dialog" title="Tax Payment" size="lg" hide-footer>
              <TaxPaymentDialog :tax_data="tax_data" :bank_account="'General Account'" @cancel="$bvModal.hide('modal-tax-payment-dialog')" @confirm="$bvModal.hide('modal-tax-payment-dialog')"  />
            </b-modal>

           
            <div class="py-2">
              <h5 class="font-size-18">Summary</h5>
              <hr class="my-2" />
            </div>
            

            <div class="table-responsive">
              <table class="table align-middle table-nowrap table-centered mb-0" style="border-color:black;">
                <thead>
                  <tr>
                    <th style="width: 200px;">Description</th>
                    <th>Reference</th>
                    <th>GST Earned</th>
                    <th>GST I.T.C</th>
                    <th class="text-end" style="width: 120px;">Net</th>
                  </tr>
                </thead>
                <!-- end thead -->
                <tbody>
                  <tr >
                    <td class="border-0" style="width: 200px;">Open Balance</td>
                    <td class="border-0" ></td>
                    <td class="border-0" >${{  tax_data.open_balance.gst_collected.toLocaleString()}}</td>
                    <td class="border-0" >${{  tax_data.open_balance.gst_itc.toLocaleString()}}</td>
                    <td class="text-end border-0" style="width: 120px;">${{  tax_data.open_balance.net.toLocaleString()}}</td>
                  </tr>
                  <tr >
                    <td class="border-0" style="width: 200px;">Active Period</td>
                    <td class="border-0" ></td>
                    <td  >${{  tax_data.active.gst_collected.toLocaleString()}}</td>
                    <td >${{  tax_data.active.gst_itc.toLocaleString()}}</td>
                    <td class="text-end" style="width: 120px;">${{  tax_data.active.net.toLocaleString()}}</td>
                  </tr>

                  <tr >
                    <td class="border-0" style="width: 200px;"></td>
                    <td class="border-0" ></td>
                    <td class="border-top-0 border-left-0 border-right-0"  style="border-bottom-width: 2px">${{  tax_data.closed.gst_collected.toLocaleString()}}</td>
                    <td class="border-top-0 border-left-0 border-right-0"  style="border-bottom-width: 2px">${{  tax_data.closed.gst_itc.toLocaleString()}}</td>
                    <td class="border-top-0 border-left-0 border-right-0 text-end"   style="width: 120px; border-bottom-width: 2px">${{  tax_data.closed.net.toLocaleString()}}</td>
                  </tr>

                  <tr >
                    <td class="border-0" style="width: 200px;"></td>
                    <td class="border-0" ></td>
                    <td class="border-0">Box103/104</td>
                    <td class="border-0">Box106/108</td>
                    <td class="text-end border-0" style="width: 120px;">Box109</td>
                  </tr>

                </tbody>
              </table>
          </div>


          <!-- Break down-->
          <div class="pt-5">
              <h5 class="font-size-18">Break Down</h5>
              <hr class="my-1" />
            </div>
           
            <div class="table-responsive">
              <table class="table align-middle table-nowrap table-centered mb-0 mt-0" style="border-color:black;">
                <thead>
                  <tr>
                    <th style="width: 200px;">Description</th>
                    <th>Reference</th>
                    <th>GST Earned</th>
                    <th>GST I.T.C</th>
                    <th class="text-end" style="width: 120px;">Net</th>
                  </tr>
                </thead>
                <!-- end thead -->
                <tbody>
                  <tr v-for="(d, idx) in tax_data.details" :key="'detail_idx_'+idx">
                    <td class="border-0" style="width: 200px;">{{ d.label }}</td>
                    <td class="border-0" ></td>
                    <td :class="idx == tax_data.details.length -1 ? 'border-top-0 border-left-0 border-right-0 local_border' : 'border-0'" >${{  d.gst_collected.toLocaleString()}}</td>
                    <td :class="idx == tax_data.details.length -1 ? 'border-top-0 border-left-0 border-right-0 local_border' : 'border-0'"  >${{  d.gst_itc.toLocaleString()}}</td>
                    <td :class="idx == tax_data.details.length -1 ? 'border-top-0 border-left-0 border-right-0 local_border text-end' : 'border-0  text-end'"  style="width: 120px;">${{  d.net.toLocaleString()}}</td>
                  </tr>


                  <tr >
                    <td class="border-0" style="width: 200px;">Closing balance</td>
                    <td class="border-0" ></td>
                    <td class="border-0"  style="border-bottom-width: 2px">${{  tax_data.closed.gst_collected.toLocaleString()}}</td>
                    <td class="border-0"  style="border-bottom-width: 2px">${{  tax_data.closed.gst_itc.toLocaleString()}}</td>
                    <td class="border-0 text-end"  style="width: 120px; border-bottom-width: 2px">${{  tax_data.closed.net.toLocaleString()}}</td>
                  </tr>

                </tbody>
              </table>
          </div>
        


          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>
