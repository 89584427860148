<script>


/**
 * Basic Info Edit component
 */



import Choices from "choices.js";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import {
    required,
} from "vuelidate/lib/validators";

import { getBrokerageApi } from "@/api/brokerage";
import { getCashApi } from '@/api/cash'
import { financial } from '@/api/misc'


export default {

    emits: ['confirm', 'cancel'],

    props: ['tax_data', 'bank_account'],
    components: {
        flatPickr
    },

    validations: {
        post_date : {required}
    },



    data() {

        return {
            bank_account_id : '',
            bank_account_list : [],
            bank_account_choice : {},
            amount           : 0,
            desc             : '',
            post_date        : '',
            general_account  : {},
            gst_collected_gl : '',
            gst_payable_gl   : ''
        }

    },
    methods: {

        f(v, d = 2) {
            return financial(v, d)
        },


        formSubmit() {
            this.$v.$touch();
            if (this.$v.$invalid == false) {
                let data = {
                    bank_account_id : this.bank_account_id,
                    remarks         : this.desc,
                    post_date       : this.post_date,
                    amount          : this.amount,
                    gst_earned      : this.tax_data.closed.gst_collected,
                    gst_itc         : this.tax_data.closed.gst_itc
                }
                getCashApi().make_tax_payment(data).then((res) => {
                    if (res.errCode == 0) {
                        this.$emit('confirm')
                    } else {
                        this.$alertify.error("Error  "+ res.errCode);
                    }
                })
            }
        },


    },

    created() {


    },

    mounted() {
        this.bank_account_choice = new Choices('#choices-bank-account', { })
        getCashApi().bank_account_list().then((res) => {
            if (res.errCode == 0) {
                this.bank_account_list = []
                res.data.accounts.map((b) => {
                  
                    this.bank_account_list.push({
                        label : b.name,
                        value : b.id,
                        bank_account : b,
                        selected : b.name == this.bank_account? true:false
                    })
                    if (b.name == this.bank_account) {
                         this.bank_account_id = b.id
                         this.general_account = b
                    }
                })

                this.bank_account_choice.setChoices(this.bank_account_list)
              
            }
        })

        getBrokerageApi().configApi.gl_config().then(res => {
            if (res.errCode == 0) {
                this.gst_payable_gl   = res.data['GLOBAL_GST_PAID_GL_CODE']
                this.gst_collected_gl = res.data['GLOBAL_GST_COLLECTION_GL_CODE']
               
            }
        })

        

        this.amount = financial(this.tax_data.closed.net, 2)


    }

};
</script>

<template>
    <div class="card">
        <div class="card-body">
            <form class="needs-validation" @submit.prevent="formSubmit">
                <div class="row">
                    <div class="col-md-4">
                        <div class="mb-3">
                            <label for="choices-bank-account" class="form-label">Bank Account</label>
                            <select class="form-control" v-model="bank_account_id" name="choices-bank-account" id="choices-bank-account" :disabled="this.bank_account? true : false">
                            </select>

                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="mb-3">
                            <label for="choices-bank-account" class="form-label">Post Date</label>
                            <flat-pickr
                                v-model="post_date"
                                placeholder="Select a date"
                                class="form-control"
                                :class="{'is-invalid':  $v.post_date.$error }"
                                ></flat-pickr>
                          <div v-if=" $v.post_date.$error" class="invalid-feedback">
                            <span v-if="!$v.post_date.required">This value is required.</span>
                          </div>

                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="mb-3">
                            <label for="validationCustom01">Amount</label>
                            <input id="validationCustom01" v-model="amount" type="text" class="form-control"
                                placeholder="$"  readonly />
                        </div>
                    </div>
                </div>
                <!-- end row-->


                <div class="row">
                    <div class="col-md-12">
                        <div class="mb-3">
                            <label for="validationCustom01">Description</label>
                            <input id="validationCustom01" v-model="desc" type="text" class="form-control"
                                placeholder="" value="" />
                        </div>
                    </div>
                </div>
                <!-- end row-->


                <div class="py-2">
                    <h5 class="font-size-18">Accounting</h5>
                    <hr class="my-2" />
                </div>
                <div class="row">
                    <div class="col-md-2 mb-3">GL Entry</div>
                    <div class="col-md-2 mb-3">Amount</div>
                    <div class="col-md-8"></div>
                </div>
                <div class="row">
                    <div class="col-md-2 mb-3">{{gst_collected_gl}}</div>
                    <div class="col-md-2 mb-3">-${{ f(tax_data.closed.gst_collected).toLocaleString() }}</div>
                    <div class="col-md-8"></div>
                </div>
                <div class="row">
                    <div class="col-md-2 mb-3">{{gst_payable_gl}}</div>
                    <div class="col-md-2 mb-3">${{ f(tax_data.closed.gst_itc).toLocaleString() }}</div>
                    <div class="col-md-8"></div>
                </div>
                <div class="row">
                    <div class="col-md-2 mb-3">{{general_account.gl_account}}</div>
                    <div class="col-md-2 mb-3">-${{f(tax_data.closed.net).toLocaleString() }}</div>
                    <div class="col-md-8"></div>
                </div>

                <div class="row pt-3">
                    <div class="col-md-4">

                    </div>
                    <div class="col-md-4">
                        <div class="d-flex flex-wrap gap-2">
                            <b-button variant="secondary" type="button" @click="$emit('cancel')">Cancel</b-button>
                            <b-button variant="primary" type="submit">Save</b-button>
                        </div>
                    </div>
                    <div class="col-md-4">

                    </div>

                </div>
                <!-- end row-->


            </form>
        </div>
    </div>
    <!-- end card -->
</template>